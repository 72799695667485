import React, {useState, useEffect, useCallback} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {isEqual} from 'lodash';
import {useParams} from 'react-router-dom';
import Modal from 'react-modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle, faLock} from '@fortawesome/free-solid-svg-icons';
import {join, setupGroups} from '../Helpers/formatData';
import TabsView from './Tabs';
import Grid from './Grid';
import Default from './Default';
import Scanner from '../Components/Scanner/Scanner';
import TradingViewWidget from '../Components/Chart/TradingViewWidget';
import OptionView from '../Components/PopOut/OptionView';
import ReportView from '../Components/PopOut/ReportView';
import LegendView from '../Components/PopOut/LegendView';
import {
  customStyles,
  customStylesOptions,
  customStylesLocked,
  customStylesUpcoming,
  customStylesReports,
  customStylesLegend,
} from './modalStyles';

const layoutOptions = {
  tabs: 'tabs-layout',
  default: 'default-layout',
  grid: 'grid-layout',
  verticalStack: 'vertical-stack-layout',
  horizontalStack: 'horizontal-stack-layout',
};

const MultiLayout = ({
  scannersData,
  layoutConfig,
  groupCollection,
  groups,
  dispatch,
  theme,
  setTheme,
  wsSocket,
  loadingGroups,
  modalIsOpen,
  userData,
  showChart,
  closeChart,
  symbolData,
  chartSettings,
  getSymbolData,
  symbolToChart,
  closeModal,
  showOptionsModal,
  openOptionsModal,
  closeOptionsModal,
  optionToView,
  showReportModal,
  openReportModal,
  closeReportModal,
  reportToView,
  groupToChart,
  showLockedModal,
  openLockedModal,
  closeLockedModal,
  showUpcomingModal,
  openUpcomingModal,
  closeUpcomingModal,
  scannerLayout,
  hasMainReport,
  reports,
  inView,
  currentDate,
  modalShow,
  handleClose,
  secondaryGroups,
  showLegendModal,
  openLegendModal,
  closeLegendModal,
}) => {
  if (!layoutConfig) {
    return null;
  }
  const params = useParams();
  const [currentGroupsInView, setCurrentGroupsInView] = useState(layoutConfig.layoutGroups[0]);
  const [currentLayout, setCurrentLayout] = useState(layoutConfig.layoutGroups[0]?.type);
  const [activeTab, setActiveTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [loadingTab, setLoadingTab] = useState(false);
  const [updatingDate, setUpdatingDate] = useState(false);

  useEffect(() => {
    const scannerId = params?.id;
    const scannerData = scannersData?.find((s) => s.slug === scannerId);
    if (!scannerData) return;
    if (scannerData?.groups?.length) {
      const setGroups = setupGroups(scannerId, scannersData);
      setAllGroups(setGroups);
    }
  }, [params, scannersData]);

  useEffect(() => {
    if (!layoutConfig || !layoutConfig?.layoutGroups?.length) {
      return;
    }
    if (isEqual(layoutConfig.layoutGroups, tabs)) {
      return;
    }
    setTabs(layoutConfig.layoutGroups);
  }, [layoutConfig]);

  useEffect(() => {
    if (!layoutConfig || !layoutConfig?.layoutGroups?.length || !groups?.length) {
      return;
    }
    const tabInView = activeTab ?? 0;
    if (layoutConfig?.layoutGroups?.[tabInView]) {
      const currentLayoutGroup = layoutConfig?.layoutGroups?.[tabInView];
      const {type, groups: groupsForLayout} = currentLayoutGroup;
      if (type) {
        Object.values(layoutOptions).forEach((option) =>
          document
            .getElementsByTagName('body')[0]
            .classList.toggle(option, layoutConfig?.layoutGroups?.[tabInView]?.type === option),
        );
        setCurrentLayout(layoutConfig.layoutGroups[tabInView]?.type);
      }
      if (groupsForLayout?.length) {
        const groupsInView = groups.filter((gr) => {
          const matchGroup = groupsForLayout.find((lg) => gr.group === lg.group);
          if (!matchGroup) return false;
          return !matchGroup.hideTab && matchGroup.type !== 'report';
        });
        if (!isEqual(groupsInView, currentGroupsInView)) {
          setCurrentGroupsInView(groupsInView);
        }
      }
    }
  }, [layoutConfig, groups, groupCollection, currentDate]);

  const handleChangeTab = useCallback(
    (index) => {
      setLoadingTab(true);
      const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
      const today = join(new Date(), acc, '-');
      if (index !== activeTab && tabs?.length) {
        const currentScanner = tabs?.[activeTab];
        const newScanner = tabs?.[index];

        const currentGroups = currentScanner?.groups?.length ? currentScanner?.groups : [];
        const newGroups = newScanner?.groups?.length ? newScanner?.groups : [];

        if (currentGroups?.length) {
          let toLeave = [];
          currentGroups.forEach((g) => {
            const {symbolReport: reportToLeave, mainReport} = g?.report ?? {
              symbolReport: null,
              mainReport: null,
            };
            toLeave = [...toLeave, g?.group];
            if (reportToLeave) {
              toLeave.push(reportToLeave);
            }
            if (mainReport) {
              toLeave.push(mainReport);
            }
          });
          if (toLeave?.length) {
            const formattedToLeave = allGroups.filter((g) => toLeave.includes(g.group));
            formattedToLeave.forEach((g) => {
              const foundInGroups = groups.find((gr) => gr.group === g?.group);
              if (foundInGroups?.joined) {
                wsSocket?.emit('group:leave', {group: `${decodeURIComponent(foundInGroups?.group)}`});
              }
            });
          }
        }
        if (newGroups?.length) {
          let toJoin = [];
          newGroups.forEach((g) => {
            const {symbolReport: newReportGroup, mainReport: newGroupMainReport} = g?.report ?? {
              symbolReport: null,
              mainReport: null,
            };
            if (!toJoin.includes(g?.group)) {
              toJoin = [...toJoin, g?.group];
            }
            if (newReportGroup && !toJoin.includes(newReportGroup)) {
              toJoin.push(newReportGroup);
            }
            if (newGroupMainReport && !toJoin.includes(newGroupMainReport)) {
              toJoin.push(newGroupMainReport);
            }
          });
          if (!toJoin?.length) return;
          const formattedToJoin = allGroups.filter((g) => toJoin.includes(g.group));
          const joinedGroups = formattedToJoin.map((g) => {
            const {group: rbGroup, type: rbType, date} = g;
            const scannerType = rbType ? decodeURIComponent(rbType) : 'tickalert';

            const groupToJoin = {
              group: `${decodeURIComponent(rbGroup)}`,
            };

            if (date) {
              groupToJoin.date = date ?? today;
            }
            if (currentDate) {
              groupToJoin.date = currentDate;
            }
            wsSocket?.emit(`${scannerType}:join`, groupToJoin);
            return {...g, sentJoin: true};
          });
          dispatch({type: 'EMIT_JOIN', payload: {joinedGroups}});
        }
        const groupsInView = groups.filter((gr) => {
          const matchGroup = layoutConfig.layoutGroups[index]?.groups.find((lg) => gr.group === lg.group);
          if (!matchGroup) return false;
          return !matchGroup.hideTab && matchGroup.type !== 'report';
        });
        if (groupsInView) {
          setCurrentGroupsInView(groupsInView);
        }
        setActiveTab(index);
        if (newScanner?.type) {
          Object.values(layoutOptions).forEach((option) =>
            document.getElementsByTagName('body')[0].classList.toggle(option, newScanner.type === option),
          );
          setCurrentLayout(newScanner.type);
        }
      }
    },
    [activeTab, tabs, groupCollection, groups, wsSocket, allGroups, currentDate],
  );

  const checkIfLoading = () => {
    if (!loadingTab) {
      return;
    }
    if (loadingTab) {
      const currentGroups = tabs?.[activeTab];
      if (!currentGroups?.groups?.length) return;
      const hasData = currentGroups.groups.every((g) => groupCollection?.[g.group]?.joined);
      if (hasData) {
        setLoadingTab(false);
      }
    }
  };

  useEffect(() => {
    checkIfLoading();
  }, [activeTab, groupCollection, groups]);

  return (
    <>
      <Tabs
        onSelect={(index) => {
          handleChangeTab(index);
        }}
        selectedIndex={activeTab}
      >
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.title}>{tab.title}</Tab>
          ))}
        </TabList>
        {/* {tabs.map((tab) => (
        <TabPanel>{tab.title}</TabPanel>
      ))} */}
        {tabs.map((tab) => (
          <TabPanel key={tab.title}>
            {loadingTab && (
              <div className="w-full h-full min-h-screen flex items-center justify-center">
                <div className="lds-dual-ring-main" />
              </div>
            )}

            {!loadingTab && tab.type === 'tabs-layout' ? (
              <TabsView
                scannersData={scannersData}
                groupCollection={groupCollection}
                groups={currentGroupsInView}
                dispatch={dispatch}
                theme={theme}
                setTheme={setTheme}
                wsSocket={wsSocket}
                loadingGroups={loadingTab}
                modalIsOpen={modalIsOpen}
                userData={userData}
                showChart={showChart}
                closeChart={closeChart}
                symbolData={symbolData}
                chartSettings={chartSettings}
                getSymbolData={getSymbolData}
                symbolToChart={symbolToChart}
                closeModal={closeModal}
                showOptionsModal={showOptionsModal}
                openOptionsModal={openOptionsModal}
                closeOptionsModal={closeOptionsModal}
                showReportModal={showReportModal}
                openReportModal={openReportModal}
                closeReportModal={closeReportModal}
                optionToView={optionToView}
                reportToView={reportToView}
                groupToChart={groupToChart}
                showLockedModal={showLockedModal}
                openLockedModal={openLockedModal}
                closeLockedModal={closeLockedModal}
                showUpcomingModal={showUpcomingModal}
                openUpcomingModal={openUpcomingModal}
                closeUpcomingModal={closeUpcomingModal}
                scannerLayout={currentLayout}
                hasMainReport={hasMainReport}
                reports={reports}
                inView={inView}
                currentDate={currentDate}
              />
            ) : null}
            {!loadingTab && tab.type === 'grid-layout' ? (
              <Grid
                scannersData={scannersData}
                groupCollection={groupCollection}
                groups={currentGroupsInView}
                dispatch={dispatch}
                theme={theme}
                setTheme={setTheme}
                wsSocket={wsSocket}
                loadingGroups={loadingGroups}
                modalIsOpen={modalIsOpen}
                userData={userData}
                showChart={showChart}
                closeChart={closeChart}
                symbolData={symbolData}
                chartSettings={chartSettings}
                getSymbolData={getSymbolData}
                symbolToChart={symbolToChart}
                closeModal={closeModal}
                showOptionsModal={showOptionsModal}
                openOptionsModal={openOptionsModal}
                closeOptionsModal={closeOptionsModal}
                showReportModal={showReportModal}
                openReportModal={openReportModal}
                closeReportModal={closeReportModal}
                optionToView={optionToView}
                reportToView={reportToView}
                groupToChart={groupToChart}
                showLockedModal={showLockedModal}
                openLockedModal={openLockedModal}
                closeLockedModal={closeLockedModal}
                showUpcomingModal={showUpcomingModal}
                openUpcomingModal={openUpcomingModal}
                closeUpcomingModal={closeUpcomingModal}
                scannerLayout={currentLayout}
                reports={reports}
              />
            ) : null}
            {!loadingTab &&
            currentGroupsInView?.length > 1 &&
            (tab.type === 'default-layout' || (tab.type !== 'tabs-layout' && tab.type !== 'grid-layout')) ? (
              <Default
                tabTitle={tab.title}
                scannersData={scannersData}
                groupCollection={groupCollection}
                groups={currentGroupsInView}
                dispatch={dispatch}
                theme={theme}
                setTheme={setTheme}
                wsSocket={wsSocket}
                loadingGroups={loadingTab}
                modalIsOpen={modalIsOpen}
                userData={userData}
                showChart={showChart}
                closeChart={closeChart}
                symbolData={symbolData}
                chartSettings={chartSettings}
                getSymbolData={getSymbolData}
                symbolToChart={symbolToChart}
                closeModal={closeModal}
                showOptionsModal={showOptionsModal}
                openOptionsModal={openOptionsModal}
                closeOptionsModal={closeOptionsModal}
                showReportModal={showReportModal}
                openReportModal={openReportModal}
                closeReportModal={closeReportModal}
                optionToView={optionToView}
                reportToView={reportToView}
                groupToChart={groupToChart}
                showLockedModal={showLockedModal}
                openLockedModal={openLockedModal}
                closeLockedModal={closeLockedModal}
                showUpcomingModal={showUpcomingModal}
                openUpcomingModal={openUpcomingModal}
                closeUpcomingModal={closeUpcomingModal}
                scannerLayout={currentLayout}
                reports={reports}
                modalShow={modalShow}
                handleClose={handleClose}
                secondaryGroups={secondaryGroups}
                currentDate={currentDate}
                updatingDate={updatingDate}
                setUpdatingDate={setUpdatingDate}
                hasMainReport={hasMainReport}
              />
            ) : null}

            {!loadingTab &&
            currentGroupsInView?.length === 1 &&
            groupCollection?.[currentGroupsInView?.[0]?.group] &&
            (tab.type === 'default-layout' || (tab.type !== 'tabs-layout' && tab.type !== 'grid-layout')) ? (
              <Scanner
                group={groupCollection[currentGroupsInView[0].group] ?? null}
                groupNumber={groups.length}
                dispatch={dispatch}
                theme={theme}
                setTheme={setTheme}
                wsSocket={wsSocket}
                openOptionsModal={openOptionsModal}
                openReportModal={openReportModal}
                showReportModal={showReportModal}
                setShowLockedModal={showLockedModal}
                optionsColumns={scannersData?.find((s) => s.slug === params?.id)?.optionsColumns ?? {}}
                getSymbolData={getSymbolData}
                optionsAllowed={scannersData?.find((s) => s.slug === params?.id)?.optionsAllowed ?? false}
                hasReportPopout={scannersData?.find((s) => s.slug === params?.id)?.hasReportPopout ?? false}
                hideHeader={false}
                trimNumbersFromSymbol={
                  scannersData?.find((s) => s.slug === params?.id)?.trimNumbersFromSymbol === true ?? false
                }
                alignColumns={scannersData?.find((s) => s.slug === params?.id)?.alignColumns ?? 'left'}
                hidePagination={
                  Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hidePagination)
                    ? scannersData?.find((s) => s.slug === params?.id)?.hidePagination
                    : scannersData?.find((s) => s.slug === params?.id)?.hidePagination === true ?? false
                }
                hideSearch={
                  Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hideSearch)
                    ? scannersData?.find((s) => s.slug === params?.id)?.hideSearch
                    : scannersData?.find((s) => s.slug === params?.id)?.hideSearch === true ?? false
                }
                useSecondaryHeaderTitle={false}
                useTabTitle
                tabTitle={tab.title}
                hideDateSelectorForGroups={
                  Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups)
                    ? scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups
                    : scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups === true ?? false
                }
                allowExportCSV={scannersData?.find((s) => s.slug === params?.id)?.allowExportCSV === true ?? false}
                openLockedModal={openLockedModal}
                groupCollection={groupCollection}
                scannerLayout={scannerLayout}
                groups={currentGroupsInView}
                hasMainReport={hasMainReport}
                updatingDate={updatingDate}
                setUpdatingDate={setUpdatingDate}
                reports={reports}
                currentDate={currentDate}
                displayLegend={scannersData?.find((s) => s.slug === params?.id)?.displayLegend ?? false}
                showLegendModal={showLegendModal}
                openLegendModal={openLegendModal}
                closeLegendModal={closeLegendModal}
              />
            ) : null}
          </TabPanel>
        ))}
      </Tabs>
      {/* Locked Modal */}
      <Modal isOpen={showLockedModal} onRequestClose={closeLockedModal} style={customStylesLocked}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeLockedModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div className="upgrade-modal">
          <h1>
            To unlock this feature, reach out in the mod chat or{' '}
            <a
              href="https://s3.amazonaws.com/assets.monumenttradersalliance.com/schedule-a-call/dpsup/index.html"
              target="_blank"
              rel="noreferrer"
            >
              click here to schedule a call with our VIP Service Squad
            </a>
            .
          </h1>
          {/* <a
              className="click-here"
              href="https://s3.amazonaws.com/assets.monumenttradersalliance.com/schedule-a-call/dpsup/index.html"
              target="_blank"
              rel="noreferrer"
            >
              Click Here to Upgrade
            </a> */}
        </div>
      </Modal>
      {/* Upcoming Modal */}
      <Modal isOpen={showUpcomingModal} onRequestClose={closeUpcomingModal} style={customStylesUpcoming}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeUpcomingModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div className="upgrade-modal">
          <h1>Feature Coming Soon</h1>
        </div>
      </Modal>
      {/* Trading Chart Modal */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <TradingViewWidget
          symbolData={symbolData}
          symbolToChart={symbolToChart}
          getSymbolData={getSymbolData}
          chartSettings={chartSettings}
          wsSocket={wsSocket}
          groupCollection={groupCollection}
          closeChart={closeChart}
          theme={theme}
          groupToChart={groupToChart}
        />
      </Modal>
      {/* Options Modal */}
      <Modal isOpen={showOptionsModal} onRequestClose={closeOptionsModal} style={customStylesOptions}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeOptionsModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div>
          <OptionView
            optionToView={optionToView}
            groupCollection={groupCollection}
            optionsLayout={scannersData?.find((s) => s.slug === params?.id)?.optionsLayout ?? {}}
          />
        </div>
      </Modal>
      {/* Report Modal */}
      <Modal isOpen={showReportModal} onRequestClose={closeReportModal} style={customStylesReports}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeReportModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div>
          <ReportView reportToView={reportToView} groupCollection={groupCollection} />
        </div>
      </Modal>
      {/* Legend Modal */}
      <Modal isOpen={showLegendModal} onRequestClose={closeLegendModal} style={customStylesLegend}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeLegendModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div>
          <LegendView groupCollection={groupCollection} />
        </div>
      </Modal>
    </>
  );
};

export default MultiLayout;
