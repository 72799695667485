import React from 'react';

const LegendView = ({groupCollection}) => {
  console.log('legend', groupCollection);
  return (
    <div>
      <div className="flex items-start justify-between flex-col gap-2">
        <div className="power-options">
          <h1>Color Legend</h1>
        </div>
      </div>
      <div>
        <div className="my-4 text-left">
          <div className="flex items-start justify-evenly gap-4">
            <div>
              <h2 className="text-xl font-bold">Size</h2>
              <div className="flex flex-col gap-4 justify-start mt-2">
                <div className="inline-flex items-baseline gap-3 justify-start m-0">
                  <span className="h-2 w-2 inline-block bg-[#2ead03] rounded-full" />
                  <span aria-label="Green" className="sr-only ">
                    Green
                  </span>
                  <span className="text-gray-600 dark:text-neutral-400">Size <span className="font-extrabold">{'>'}</span> Open Interest</span>
                </div>
                <div className="inline-flex items-baseline gap-3 justify-start m-0">
                  <span className="h-2 w-2 inline-block bg-[#FFAC1C] rounded-full" />
                  <span aria-label="Orange" className="sr-only ">
                    Orange
                  </span>
                  <span className="text-gray-600 dark:text-neutral-400">
                    Size <span className="font-extrabold">{'>'}</span> 1 <br /> Size <span className="font-extrabold">{'>'}</span> Open Interest
                  </span>
                </div>
                <div className="inline-flex items-baseline gap-3 justify-start m-0">
                  <span className="h-2 w-2 inline-block bg-[#FFEA00] rounded-full" />
                  <span aria-label="Yellow" className="sr-only ">
                    Yellow
                  </span>
                  <span className="text-gray-600 dark:text-neutral-400">
                    Size <span className="font-extrabold">{'>'}</span> Open Interest <br /> Count<span className="font-extrabold"> = </span>1
                  </span>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-xl font-bold">Count</h2>
              <div className="flex flex-col gap-2 justify-start items-start mt-4">
                <div className="inline-flex items-baseline gap-3 justify-start m-0">
                  <span className="h-2 w-2 inline-block bg-[#FFAC1C] rounded-full" />
                  <span aria-label="Orange" className="sr-only ">
                    Orange
                  </span>
                  <span className="text-gray-600 dark:text-neutral-400">
                    Count <span className="font-extrabold">{'>'}</span> 1 <br /> Size <span className="font-extrabold">{'>'}</span> Open Interest
                  </span>
                </div>
                <div className="inline-flex items-baseline gap-3 justify-start m-0">
                  <span className="h-2 w-2 inline-block bg-[#FFEA00] rounded-full" />
                  <span aria-label="Yellow" className="sr-only ">
                    Yellow
                  </span>
                  <span className="text-gray-600 dark:text-neutral-400">
                    Size <span className="font-extrabold">{'>'}</span> Open Interest <br /> Count<span className="font-extrabold"> = </span>1
                  </span>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-xl font-bold">Open Interest</h2>
              <div className="flex flex-col gap-2 justify-start items-start mt-4">
                <div className="inline-flex items-baseline gap-3 justify-start m-0">
                  <span className="h-2 w-2 inline-block bg-[#FFAC1C] rounded-full" />
                  <span aria-label="Orange" className="sr-only ">
                    Orange
                  </span>
                  <span className="text-gray-600 dark:text-neutral-400">
                    Count <span className="font-bold">{'>'}</span> 1 <br /> Size <span className="font-extrabold">{'>'}</span> Open Interest
                  </span>
                </div>
                <div className="inline-flex items-baseline gap-3 justify-start m-0">
                  <span className="h-2 w-2 inline-block bg-[#FFEA00] rounded-full" />
                  <span aria-label="Yellow" className="sr-only ">
                    Yellow
                  </span>
                  <span className="text-gray-600 dark:text-neutral-400">
                    Size <span className="font-extrabold">{'>'}</span> Open Interest <br /> Count<span className="font-extrabold"> = </span>1
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegendView;
